<template>
  <div id="patient-document-list">
    <!-- dialog -->

    <v-dialog
      v-if="isNewDocumentFormActive"
      v-model="isNewDocumentFormActive"
      max-width="75%"
    >
      <document-add-edit
        v-if="isNewDocumentFormActive"
        :is-document-form-active="isNewDocumentFormActive"
        :doc="selectedDocument"
        :is-edit="true"
        @update-dialog="updateRenderDialog"
      ></document-add-edit>
    </v-dialog>

    <v-dialog
      v-if="isErrorMarkDialogActive"
      persistent
      v-model="isErrorMarkDialogActive"
      max-width="75%"
    >
      <error-mark-confirmation
        v-if="isErrorMarkDialogActive"
        v-model="isErrorMarkDialogActive"
        :selected-item="selectedItem"
        type="PatientDocument"
        @error-mark-confirmation="submitErrorMark"
        @refetch-data="fetchData"
      ></error-mark-confirmation>
    </v-dialog>

    <v-dialog
      v-if="renderDialog"
      v-model="renderDialog"
      max-width="75%"
    >
    <v-card>
      <div>
          <hr />
          <VuePdfEmbed :source="selectedDocument" />
        </div>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title> Patient Documents</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          max-width="20px"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="document-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <task-type-select
          :multiple="false"
          @task-type-select="updateType"
        ></task-type-select>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isNewDocumentFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="selectedDocument = null, isNewDocumentFormActive = !isNewDocumentFormActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Document</span>
          </v-btn>
        </div>
      </v-card-text>
      <!-- table -->
      <v-data-table
        v-model="selectedRow"
        :headers="patientDocumentColumns"
        :items="pdfList"
        :options.sync="docOptions"
        :search="searchQuery"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedDocument = item.document, selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateDialog('render')"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isErrorMarkDialogActive = !isErrorMarkDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRemoveOutline }}
                  </v-icon>
                  <span>Mark Error</span>
                </v-btn>
              </v-list-item>


            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFileRemoveOutline,
} from '@mdi/js'

import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import DocumentAddEdit from '@/views/review/DocumentAddEdit.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import ErrorMarkConfirmation from '@/components/validation/ErrorMarkConfirmation.vue'

import errorMarkService from '@/services/ErrorMarkService'
import useErrorMarkService from '@/store/useErrorMarkService'

export default {
  components: { TaskTypeSelect, DocumentAddEdit, VuePdfEmbed, ErrorMarkConfirmation },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup() {
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) {
        store.unregisterModule(FORM_APP)
      }
    })

    const {
      fetchPatientPDFs,
      pdfList,
      loading,
      searchQuery,
      patientDocumentColumns,
      selectedDocument,
      selectedRow,

      docOptions,
      statusFilter,
      documentData,
      groupFilter,
    } = useFormService()

    const ERROR_APP = 'app-error'

    // Register module
    if (!store.hasModule(ERROR_APP)) {
      store.registerModule(ERROR_APP, errorMarkService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_APP)) {
        store.unregisterModule(ERROR_APP)
      }
    })

    const {
      postErrorMark
    } = useErrorMarkService()

    const selectedItem = ref({})
    const isDeleteDialogActive = ref(false)
    const isNewDocumentFormActive = ref(false)
    const renderDialog = ref(false)
    const isErrorMarkDialogActive = ref(false)

    return {
      pdfList,
      patientDocumentColumns,
      searchQuery,
      statusFilter,
      loading,
      docOptions,
      selectedDocument,
      renderDialog,
      selectedItem,
      selectedRow,
      isDeleteDialogActive,
      isNewDocumentFormActive,
      isErrorMarkDialogActive,
      documentData,
      fetchPatientPDFs,

      groupFilter,
      postErrorMark,

      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiFileRemoveOutline,
      },
    }
  },
  mounted() {
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientPDFs(this.patientData.id)
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.renderDialog = !this.renderDialog
    },
    submitErrorMark(errorMarkDTO) {
      errorMarkDTO.patientId = this.patientData.id

      errorMarkDTO.entityJSON.modifiedDate = new Date(errorMarkDTO.entityJSON.modifiedDate)

      console.log('Posting Error Mark', errorMarkDTO)
      this.postErrorMark(errorMarkDTO)
    },
    fetchData() {
      this.fetchPatientPDFs(this.patientData.id)
      this.$watch('pdfList', () => {
      })
    },
    updateType(item) {
      this.groupFilter = []
      if (item !== null) {
        if (!this.groupFilter.includes(item.display)) {
          this.groupFilter.push(item.display)
        }
      }
      this.fetchPatientPDFs(this.patientData.id)
      this.$watch('pdfList', () => {
      })
    },
    updateDialog(dialog) {
      if (dialog === 'render') {
        if (this.renderDialog) {
          this.selectedDocument = {}
          this.selectedItem = {}
        }
        if (this.selectedDocument == null) {
          this.renderDialog = false
        }
        this.renderDialog = !this.renderDialog
      }
    },
    updateRenderDialog(status) {
      if (status == null) {
        this.selectedItem = {}
        this.selectedDocument = {}
      }
      this.isNewDocumentFormActive = !this.isNewDocumentFormActive
    },
  },
}
</script>
