<template>
  <div id="patient-tuberculosis-list">
    <!-- dialog -->

    <v-dialog
      v-model="isTuberculosisFormActive"
    >
      <tuberculosis-add-edit
        v-model="isTuberculosisFormActive"
        :is-edit="isEdit"
        :patient-tuberculosis-d-t-o="selectedItem"
        @add-tuberculosis="isTuberculosisFormActive"
        @refetch-data="fetchData"
      ></tuberculosis-add-edit>
    </v-dialog>

    <div
      v-if="selectedItem != null"
    >
      <error-mark-confirmation
        v-if="isErrorMarkDialogActive"
        v-model="isErrorMarkDialogActive"
        :selected-item="selectedItem"
        type="PatientTuberculosis"
        @error-mark-confirmation="submitErrorMark"
        @refetch-data="fetchData"
      ></error-mark-confirmation>
    </div>

    <v-card>
      <v-card-title> Patient Tuberculosis Tests</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          max-width="20px"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="tuberculosis-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isTuberculosisFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="isTuberculosisFormActive = !isTuberculosisFormActive, isEdit = true, selectedItem = {patientId: patientId}"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New TB Test</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRow"
        :headers="patientTBColumns"
        :items="tbList"
        :search="searchQuery"
        :loading="loading"
        :options.sync="tbOptions"
      >
        <!-- actions -->
        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <template #[`item.recordedDate`]="{item}">
          {{ formatDate(item.recordedDate) }}
        </template>
        <template #[`item.modifiedDate`]="{item}">
          {{ formatDate(item.modifiedDate) }}
        </template>
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isTuberculosisFormActive = !isTuberculosisFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item
                v-if="selectedItem != null && !selectedItem.read"
                link
              >
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isTuberculosisFormActive = !isTuberculosisFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isErrorMarkDialogActive = !isErrorMarkDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRemoveOutline }}
                  </v-icon>
                  <span>Mark Error</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateStatus(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span v-if="item.isActive">Disable</span>
                  <span v-else>Activate</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiMinus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFileRemoveOutline,
} from '@mdi/js'

import formService from '@/services/FormService'
import useFormList from '@/store/useFormService'
import TuberculosisAddEdit from '@/views/tuberculosis/PatientTuberculosisAddEdit.vue'
import CommonList from '@/store/CommonService'

import ErrorMarkConfirmation from '@/components/validation/ErrorMarkConfirmation.vue'

import errorMarkService from '@/services/ErrorMarkService'
import useErrorMarkService from '@/store/useErrorMarkService'

export default {
  components: { TuberculosisAddEdit, ErrorMarkConfirmation },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup() {
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) {
        store.unregisterModule(FORM_APP)
      }
    })

    const {
      tbList,
      patientTBColumns,
      searchQuery,
      loading,
      selectedRow,
      tbOptions,

      fetchPatientTBForms,
      postPatientTBForm,
    } = useFormList()

    const { formatDate, readStatus } = CommonList()

    const ERROR_APP = 'app-error'

    // Register module
    if (!store.hasModule(ERROR_APP)) {
      store.registerModule(ERROR_APP, errorMarkService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_APP)) {
        store.unregisterModule(ERROR_APP)
      }
    })

    const {
      postErrorMark
    } = useErrorMarkService()

    const isErrorMarkDialogActive = ref(false)

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isTuberculosisFormActive = ref(false)

    return {
      tbList,
      patientTBColumns,
      searchQuery,
      loading,
      selectedRow,
      isTuberculosisFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      tbOptions,

      fetchPatientTBForms,
      postPatientTBForm,
      formatDate,
      readStatus,

      postErrorMark,
      isErrorMarkDialogActive,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiMinus,
        mdiFileRemoveOutline,
      },
    }
  },
  mounted() {
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientTBForms(this.patientData.id)
    }
    else if (this.patientId != null && this.patientId !== undefined) {
      this.fetchPatientTBForms(this.patientId)
    }

    this.$watch('tbList', () => {
      console.log('tbList', this.tbList)
    })
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    updateStatus(item) {
      this.selectedItem = item
      this.selectedItem.isActive = !item.isActive
      console.log(this.selectedItem)
      this.postPatientTBForm(this.selectedItem)
    },
    fetchData() {
      this.fetchPatientTBForms(this.patientId)
    },
    submitErrorMark(errorMarkDTO) {
      errorMarkDTO.patientId = this.patientData.id

      errorMarkDTO.entityJSON.modifiedDate = new Date(errorMarkDTO.entityJSON.modifiedDate)

      console.log('Posting Error Mark', errorMarkDTO)
      this.postErrorMark(errorMarkDTO)
    },
  },
}
</script>
