<template>
  <div id="patient-alert-list">
    <!-- dialog -->

    <v-dialog
      v-model="isAlertFormActive"
    >
      <alert-add-edit
        v-model="isAlertFormActive"
        :is-edit="isEdit"
        :patient-alert-d-t-o="selectedItem"
        @add-alert="isAlertFormActive"
        @refetch-data="fetchData"
      ></alert-add-edit>
    </v-dialog>

    <v-card>
      <v-card-title> Patient Alerts</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          max-width="20px"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="alert-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isAlertFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="isAlertFormActive = !isAlertFormActive, isEdit = true, selectedItem = {patientId: patientId}"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Alert</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedAlert"
        :headers="patientAlertColumns"
        :items="patientAlerts"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >


      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
            <td :colspan="headers.length">
                <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen"> {{icons.mdiMinus}}</v-icon>
                    <v-icon v-else>{{icons.mdiPlus}}</v-icon>
                </v-btn>
                Active - {{ group }}
            </td>
        </template>



        <!-- actions -->
        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <template #[`item.startDate`]="{item}">
          {{ formatDate(item.startDate) }}
        </template>
        <template #[`item.expectedResolutionDate`]="{item}">
          {{ formatDate(item.expectedResolutionDate) }}
        </template>
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isAlertFormActive = !isAlertFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isAlertFormActive = !isAlertFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateStatus(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span v-if="item.isActive">Disable</span>
                  <span v-else>Activate</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiMinus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import patientService from '@/services/PatientService'
import usePatientList from '@/store/usePatientService'
import AlertAddEdit from '@/views/alert/PatientAlertAddEdit.vue'
import CommonList from '@/store/CommonService'

export default {
  components: { AlertAddEdit },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup() {
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) {
        store.unregisterModule(PATIENT_APP)
      }
    })

    const {
      patientAlerts,
      patientAlertColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAlert,

      fetchPatientAlerts,
      postPatientAlert,
      deleteAlert,
    } = usePatientList()

    const { formatDate, readStatus } = CommonList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isAlertFormActive = ref(false)

    return {
      patientAlerts,
      patientAlertColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAlert,
      isAlertFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,

      deleteAlert,
      fetchPatientAlerts,
      postPatientAlert,
      formatDate,
      readStatus,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiMinus,
      },
    }
  },
  mounted() {
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientAlerts(this.patientData.id)
    }
    else if (this.patientId != null && this.patientId !== undefined) {
      this.fetchPatientAlerts(this.patientId)
    }

    this.$watch('patientAlerts', () => {
      Object.keys(this.$refs).forEach(k => {
        if (k == "false") {
            this.$refs[k].$el.click()
        }
      })
    })
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    submitDelete(alertDTO) {
      this.deleteAlert(alertDTO)
    },
    updateStatus(item) {
      this.selectedItem = item
      this.selectedItem.isActive = !item.isActive
      console.log(this.selectedItem)
      this.postPatientAlert(this.selectedItem)
    },
    fetchData() {
      this.fetchPatientAlerts(this.patientId)
    },
    log(msg) {
      console.log(msg)
    },
  },
}
</script>
