<template>
  <div>
    <v-dialog
      v-model="isVitalsListActive"
    >
      <v-card>
        <patient-vitals-list
          v-model="isVitalsListActive"
          :patient-data="patientData"
          :is-vitals-list-active="isVitalsListActive"
        ></patient-vitals-list>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="align-start">
        <span>Patient Vitals</span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
          @click="isVitalsListActive = !isVitalsListActive"
        >
          <v-icon size="22">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle><span> * updated {{patientVitals.modifiedDate}}</span></v-card-subtitle>
      <v-card-text>
        <v-row>

          <v-col
            cols="4"
          >
            <p
              class="text-xs-center"
            >
              Blood Pressure (mmHg)
            </p>

            <h1>
              <span
                :id="'systolic' + isBetween(patientVitals.systolic, 'Systolic')"
              >
              {{patientVitals.systolic}}
              </span>
                /
              <span
                :id="'diastolic' + isBetween(patientVitals.diastolic, 'Diastolic')"
              >
              {{patientVitals.diastolic}}
              </span>
            </h1>

          </v-col>
          <v-col
            cols="4"
          >
            <p
              class="text-xs-center"
            >
              Pulse (BPM)
            </p>
            <h1>
              <div
                :id="'pulse' + isBetween(patientVitals.pulse, 'Pulse')"
              >
              {{patientVitals.pulse}}
              </div>
            </h1>
          </v-col>
          <v-col
            cols="4"
          >
            <p
              class="text-xs-center"
            >
              Blood Sugar
            </p>
            <h1
              id="bloodSugar"
            >
              <div
                :id="'bloodSugar' + isBetween(patientVitals.bloodSugar, 'BloodSugar')"
              >
              {{patientVitals.bloodSugar}}
              </div>
            </h1>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { betweenValidator } from '@core/utils/validation'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import PatientVitalsList from '@/views/patient/list/PatientVitalsList.vue'
import patientService from '@/services/PatientService'
import usePatientService from '@/store/usePatientService'

export default {
  components: { PatientVitalsList },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
      isVitalsListActive: false,
    }
  },
  setup() {
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })

    const {
      loading,
      fetchLatestVitals,
      patientVitals,
    } = usePatientService()

    const vitalRec = {
      Temperature: { min: 94, max: 102 },
      Pulse: { min: 60, max: 100 },
      Respirations: { min: 11, max: 35 },
      O2SaturationPercent: { min: 84, max: 100 },
      Systolic: { min: 90, max: 180 },
      Diastolic: { min: 60, max: 120 },
      Weight: { min: 80, max: 650 },
      Height: { min: 35, max: 350 },
      PrePeakFlow: { min: 200, max: 550 },
      PostPeakFlow: { min: 200, max: 550 },
      BloodSugar: { min: 59, max: 356 },
    }

    return {
      loading,
      fetchLatestVitals,
      patientVitals,

      betweenValidator,
      vitalRec,

      icons: {
        mdiDotsVertical,
      },
    }
  },
  mounted(){
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchLatestVitals(this.patientData.id)
    }
    else if (this.patientId != null && this.patientId !== undefined) {
      this.fetchLatestVitals(this.patientId)
    }

    this.$watch('patientVitals', () => {
      console.log('patientVitals', this.patientVitals)
    })
  },
  methods: {
    isBetween (value, index) {
      if(this.betweenValidator(value, this.vitalRec[index]['min'], this.vitalRec[index]['max']) == true){
        return 'Good'
      }
      else{
        return 'Bad'
      }
    }
  },
}
</script>

<style>
#bloodSugarGood {
  color: rgb(22, 187, 22);
}
#bloodSugarBad {
  color: rgb(240, 41, 41);
}
#pulseGood {
  color: rgb(22, 187, 22);
}
#pulseBad {
  color: rgb(240, 41, 41);
}
#diastolicGood {
  color: rgb(22, 187, 22);
}
#diastolicBad {
  color: rgb(240, 41, 41);
}
#systolicGood {
  color: rgb(22, 187, 22);
}
#systolicBad {
  color: rgb(240, 41, 41);
}
</style>
