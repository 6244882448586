<template>
  <div>
    <v-dialog
      v-model="isTreatmentFormActive"
      persistent
      max-width="75%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Treatment Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="PatientTreatmentForm"
          >
            <v-row>
              <v-col
                cols="12"
                lg="4"
              >
                <distribution-type-select
                  :distribution-type="patientTreatmentDTO.distributionType"
                  :filter="distTypeFilter"
                  @distribution-type-select="updateDistributionType"
                ></distribution-type-select>
              </v-col>

              <v-col
                cols="12"
                lg="4"
              >
                <prescriber-select
                  :prescriber="patientTreatmentDTO.prescriber"
                  @prescriber-select="updatePrescriber"
                ></prescriber-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-textarea
                  v-model="patientTreatmentDTO.directions"
                  label="Directions"
                  hint="Take 200mg daily"
                  outlined
                  dense
                  clearable
                  rows="1"
                  :readonly="!isEdit"
                  :rules="[required]"
                  auto-grow
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                lg="4"
              >
                <billing-type-select
                  :billing-type="patientTreatmentDTO.billingType"
                  @billing-type-select="updateBillingType"
                ></billing-type-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="12"
              >
                <distribution-schedule-select
                  :key="distType"
                  :schedule="patientTreatmentDTO.distributionSchedule"
                  :is-edit="isEdit"
                  :type="distType"
                  @distribution-schedule-select="updateDistributionSchedule"
                ></distribution-schedule-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <form-select
                  @form-select="updateFormSelect"
                ></form-select>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                lg="2"
              >
                <v-switch
                  v-model="patientTreatmentDTO.isActive"
                  color="primary"
                  :value="patientTreatmentDTO.isActive != null ? patientTreatmentDTO.isActive : true"
                  :label="patientTreatmentDTO.isActive ? 'Active' : 'Inactive'"
                  :readonly="!isEdit"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="isEdit"
                  color="primary darken-1"
                  text
                  @click="submitForm(patientTreatmentDTO)"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'
import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'
import PrescriberSelect from '@/components/medication/PrescriberSelect.vue'
import DistributionTypeSelect from '@/components/medication/DistributionTypeSelect.vue'
import BillingTypeSelect from '@/components/medication/BillingTypeSelect.vue'
import DistributionScheduleSelect from '@/components/medication/DistributionScheduleSelect.vue'
import FormSelect from '@/components/patient/FormSelect.vue'

export default {
  components: {
    PrescriberSelect,
    DistributionTypeSelect,
    DistributionScheduleSelect,
    FormSelect,
    BillingTypeSelect,
  },
  model: {
    prop: 'isTreatmentFormActive',
    event: 'update:is-medication-form-active',
  },
  props: {
    isTreatmentFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientTreatmentDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup(props, { emit }) {
    //console.log('Patient Script', props.isEdit)

    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { postPatientTreatment } = useMedicationList()

    const patientTreatmentForm = ref(null)
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)
    const pack = ref(null)
    const route = ref(null)
    const distType = ref(null)
    const distTypeFilter = ['Keep On Person', 'HomeMed']

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog', 'render')
    }

    return {
      patientTreatmentForm,
      closeForm,
      refreshParent,
      distTypeFilter,
      distType,
      required,
      menuID,
      menuLO,
      menuref,
      save,
      pack,
      route,

      postPatientTreatment,
    }
  },
  methods: {
    validate() {
      if (this.patientTreatmentForm.value.validate(this.patientTreatmentDTO)) {
        return true
      }

      return false
    },

    submitForm() {
      this.patientTreatmentDTO.patientId = this.patientId
      //console.log('posting', this.patientTreatmentDTO)

      this.postPatientTreatment(this.patientTreatmentDTO)

      this.closeForm()
      this.refreshParent()
    },
    updatePrescriber(prescriber) {
      this.patientTreatmentDTO.prescriberId = prescriber.id
      //console.log('prescriber update', prescriber, this.patientTreatmentDTO)
    },
    updateDistributionType(distributionType) {
      this.patientTreatmentDTO.distributionType = distributionType
      this.distType = distributionType
      //console.log('Distribution Type update', distributionType, this.patientTreatmentDTO)
    },
    updateBillingType(billingType) {
      this.patientTreatmentDTO.billingType = billingType
      //console.log('Billing Type update', billingType, this.patientTreatmentDTO)
    },
    updateDistributionSchedule(schedule) {
      this.patientTreatmentDTO.distributionSchedule = schedule
      //console.log('Distribution Schedule update', schedule, this.patientTreatmentDTO)
    },
    updateFormSelect(form) {
      this.patientTreatmentDTO.formStyleId = form.formStyleId
      //console.log('Form Select update', form, this.patientTreatmentDTO)
    },
  },
}
</script>
