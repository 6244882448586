<template>
  <div
    v-if="sitePatient != null"
    id="patient-chart"
  >

    <v-row>
      <v-col
        cols="12"
      >
        <patient-chart-popups :patient-data="sitePatient"></patient-chart-popups>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <patient-chart-header :patient-data="sitePatient"></patient-chart-header>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="7"
      >
        <patient-chart-vitals :patient-data="sitePatient"></patient-chart-vitals>
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="6"
      >
        <patient-chart-timeline></patient-chart-timeline>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <patient-chart-allergy :patient-data="sitePatient"></patient-chart-allergy>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <patient-chart-alert :patient-data="sitePatient"></patient-chart-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <patient-chart-problem :patient-data="sitePatient"></patient-chart-problem>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <patient-chart-treatments :patient-data="sitePatient"></patient-chart-treatments>
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col
          cols="12"
        >
          <patient-chart-medications :patient-data="sitePatient"></patient-chart-medications>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
      <v-col
        cols="12"
      >
        <patient-chart-immunizations :patient-data="sitePatient"></patient-chart-immunizations>
      </v-col>
    </v-row>
    </div>
    <div>
      <v-row>
      <v-col
        cols="12"
      >
        <patient-chart-tuberculosis :patient-data="sitePatient"></patient-chart-tuberculosis>
      </v-col>
    </v-row>
    </div>
    <div>
      <v-row>
        <v-col
          cols="12"
        >
          <patient-chart-forms :patient-data="sitePatient"></patient-chart-forms>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col
          cols="12"
        >
          <patient-chart-documents :patient-data="sitePatient"></patient-chart-documents>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import patientService from '@/services/PatientService'
import usePatientService from '@/store/usePatientService'
import PatientChartHeader from './PatientChartHeader.vue'
import PatientChartTimeline from './PatientChartTimeline.vue'
import PatientChartProblem from './PatientChartProblem.vue'
import PatientChartMedications from './PatientChartMedications.vue'
import PatientChartVitals from './PatientChartVitals.vue'
import PatientChartAllergy from './PatientChartAllergy.vue'
import PatientChartAlert from './PatientChartAlert.vue'
import PatientChartPopups from './PatientChartPopups.vue'
import PatientChartForms from './PatientChartForms.vue'
import PatientChartTreatments from './PatientChartTreatments.vue'
import PatientChartImmunizations from './PatientChartImmunizations.vue'
import PatientChartDocuments from './PatientChartDocuments.vue'
import PatientChartTuberculosis from './PatientChartTuberculosis.vue'

export default {
  components: {
    PatientChartHeader,
    PatientChartTimeline,
    PatientChartProblem,
    PatientChartMedications,
    PatientChartVitals,
    PatientChartAllergy,
    PatientChartAlert,
    PatientChartPopups,
    PatientChartForms,
    PatientChartTreatments,
    PatientChartImmunizations,
    PatientChartDocuments,
    PatientChartTuberculosis,
  },
  data() {
    return {
      patientData: {},
      patientId: this.$route.params.patientId,
    }
  },
  mounted() {
    this.fetchPatient(this.patientId)
  },
  setup() {
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })

    const {
      sitePatient,

      fetchPatient,
    } = usePatientService()

    return {
      sitePatient,

      fetchPatient,
    }
  },
}
</script>
