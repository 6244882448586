<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col
          cols="4"
        >
          <h4><strong>Patient Name: </strong> {{ patientData.lastName }}, {{ patientData.firstName }}</h4>
        </v-col>
        <v-col
          cols="4"
        >
          <h4><strong>Patient ID: </strong> {{ patientData.jacketId }}</h4>
        </v-col>
        <v-col
          cols="4"
        >
          <h4><strong>Booking ID: </strong> {{ patientData.bookingId }}</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="4"
        >
          <h4><strong>Date of Birth: </strong> {{ new Date(patientData.birthDate).toISOString().split('T')[0] }}</h4>
        </v-col>
        <v-col
          cols="4"
        >
          <h4><strong>Location: </strong> {{ formatLocation(patientData.patientSites) }}</h4>
        </v-col>
        <v-col
          cols="4"
        >
          <h4><strong>Booking Date: </strong> {{ new Date(patientData.bookingDate).toISOString().split('T')[0] }}</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="4"
        >
          <h4><strong>Age: </strong> {{ patientData.age }}</h4>
        </v-col>
        <v-col
          cols="4"
        ></v-col>

        <v-col
          cols="4"
        >
          <h4><strong>Previous Release Date: </strong> {{ new Date(patientData.releaseDate).toISOString().split('T')[0] }}</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="4"
        >
          <h4><strong>Gender: </strong> {{ patientData.gender.display }}</h4>
        </v-col>
        <v-col
          cols="4"
        >
          <h4><strong>Race: </strong> {{ patientData.race.display }}</h4>
        </v-col>
        <v-col
          cols="4"
        >
          <h4><strong>Status: </strong> {{ patientData.isActive ? 'Active' : 'Inactive' }}</h4>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import patientService from '@/services/PatientService'
import usePatientService from '@/store/usePatientService'
import moment from 'moment'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

export default {
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })

    const { formatLocation } = usePatientService()

    return {
      formatLocation,
      icons: {
        mdiDotsVertical,
      },
    }
  },
  methods: {
    formatDate(date) {
      return moment(String(date)).format('MM/DD/YYYY')
    },
    calculateAge(dob) {
      const currentDate = new Date()
      const difference = currentDate - dob
      const age = Math.floor(difference / 31557600000)

      return age
    },
  },
}
</script>
