<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Activity Timeline</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          small
          color="error"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Patient Released
            </h4>
            <small class="text-no-wrap">Wednesday</small>
          </div>
          <p class="mb-0">
            Active Tasks have been deactivated.
          </p>
        </v-timeline-item>
        <v-timeline-item
          small
          color="primary"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Patient Sick Call Requested
            </h4>
            <small class="text-no-wrap">April, 18</small>
          </div>
          <p class="mb-0">
            Sick Call Performed by: Dr. Nate<small
              class="text-no-wrap"
              style="float:right"
            >April, 19</small>
          </p>
        </v-timeline-item>
        <v-timeline-item
          small
          color="info"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Patient Allergy Update
            </h4>
            <small class="text-no-wrap">January, 10</small>
          </div>
          <p class="mb-0">
            Morphine
          </p>
        </v-timeline-item>
        <v-timeline-item
          small
          color="success"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Patient Tasks Generated
            </h4>
            <small class="text-no-wrap">September, 30</small>
          </div>
          <p class="mb-0">
            Extended Medical History <small
              class="text-no-wrap"
              style="float:right"
            >Due October, 14</small>
          </p>
          <p class="mb-0">
            TB Test and Read <small
              class="text-no-wrap"
              style="float:right"
            >Due October, 14</small>
          </p>
        </v-timeline-item>
        <v-timeline-item
          small
          color="warning"
        >
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              Patient Intake
            </h4>
            <small class="text-no-wrap">September, 30</small>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>
