import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"patient-alert-list"}},[_c(VDialog,{model:{value:(_vm.isAlertFormActive),callback:function ($$v) {_vm.isAlertFormActive=$$v},expression:"isAlertFormActive"}},[_c('alert-add-edit',{attrs:{"is-edit":_vm.isEdit,"patient-alert-d-t-o":_vm.selectedItem},on:{"add-alert":_vm.isAlertFormActive,"refetch-data":_vm.fetchData},model:{value:(_vm.isAlertFormActive),callback:function ($$v) {_vm.isAlertFormActive=$$v},expression:"isAlertFormActive"}})],1),_c(VCard,[_c(VCardTitle,[_vm._v(" Patient Alerts")]),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"alert-search me-3 mb-4",attrs:{"max-width":"20px","placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAlertFormActive = !_vm.isAlertFormActive, _vm.isEdit = true, _vm.selectedItem = {patientId: _vm.patientId}}},model:{value:(_vm.isAlertFormActive),callback:function ($$v) {_vm.isAlertFormActive=$$v},expression:"isAlertFormActive"}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Alert")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.patientAlertColumns,"items":_vm.patientAlerts,"options":_vm.options,"search":_vm.searchQuery,"loading":_vm.loading,"group-by":"isActive","group-desc":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VBtn,{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiMinus))]):_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1),_vm._v(" Active - "+_vm._s(group)+" ")],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.readStatus(item.isActive))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}},{key:"item.expectedResolutionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expectedResolutionDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.selectedItem = item}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isEdit = false, _vm.isAlertFormActive = !_vm.isAlertFormActive}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isEdit = true, _vm.isAlertFormActive = !_vm.isAlertFormActive}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountEditOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.updateStatus(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountLockOutline)+" ")]),(item.isActive)?_c('span',[_vm._v("Disable")]):_c('span',[_vm._v("Activate")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedAlert),callback:function ($$v) {_vm.selectedAlert=$$v},expression:"selectedAlert"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }