<template>
  <div id="vitals-list">

    <div
      v-if="selectedItem != null"
    >
      <error-mark-confirmation
        v-if="isErrorMarkDialogActive"
        v-model="isErrorMarkDialogActive"
        :selected-item="selectedItem"
        type="PatientVitals"
        @error-mark-confirmation="submitErrorMark"
        @refetch-data="fetchData"
      ></error-mark-confirmation>
    </div>

    <v-card>
      <v-card-text>
          <div>
            <v-row>
              <v-card-title> {{ patientData.jacketId }} | {{patientData.displayName}} Vitals</v-card-title>


              <v-spacer></v-spacer>

              <v-col
                md="auto"
              >
                <v-btn
                  color="error"
                  @click="closeForm()"
                >
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedItem"
        :headers="patientVitalsColumns"
        :items="patientVitalsList"
        :options.sync="options"
        :loading="loading"
      >
      <template #[`item.temperature`]="{item}">
        <div
          :id="'temperature' + isBetween(item.temperature, 'Temperature')"
          dark
        >
          {{ item.temperature }}
        </div>
      </template>
      <template #[`item.respirations`]="{item}">
        <div
          :id="'respirations' + isBetween(item.respirations, 'Respirations')"
          dark
        >
          {{ item.respirations }}
        </div>
      </template>
      <template #[`item.o2SaturationPercent`]="{item}">
        <div
          :id="'o2SaturationPercent' + isBetween(item.o2SaturationPercent, 'O2SaturationPercent')"
          dark
        >
          {{ item.o2SaturationPercent }}
        </div>
      </template>
      <template #[`item.systolic`]="{item}">
        <div
          :id="'systolic' + isBetween(item.systolic, 'Systolic')"
          dark
        >
          {{ item.systolic }}
        </div>
      </template>
      <template #[`item.diastolic`]="{item}">
        <div
          :id="'diastolic' + isBetween(item.diastolic, 'Diastolic')"
          dark
        >
          {{ item.diastolic }}
        </div>
      </template>
      <template #[`item.pulse`]="{item}">
        <div
          :id="'pulse' + isBetween(item.pulse, 'Pulse')"
          dark
        >
          {{ item.pulse }}
        </div>
      </template>
      <template #[`item.weight`]="{item}">
        <div
          :id="'weight' + isBetween(item.weight, 'Weight')"
          dark
        >
          {{ item.weight }}
        </div>
      </template>
      <template #[`item.height`]="{item}">
        <div
          :id="'height' + isBetween(item.height, 'Height')"
          dark
        >
          {{ item.height }}
        </div>
      </template>
      <template #[`item.prePeakFlow`]="{item}">
        <div
          :id="'prePeakFlow' + isBetween(item.prePeakFlow, 'PrePeakFlow')"
          dark
        >
          {{ item.prePeakFlow }}
        </div>
      </template>
      <template #[`item.postPeakFlow`]="{item}">
        <div
          :id="'postPeakFlow' + isBetween(item.postPeakFlow, 'PostPeakFlow')"
          dark
        >
          {{ item.postPeakFlow }}
        </div>
      </template>
      <template #[`item.bloodSugar`]="{item}">
        <div
          :id="'bloodSugar' + isBetween(item.bloodSugar, 'BloodSugar')"
          dark
        >
          {{ item.bloodSugar }}
        </div>
      </template>

      <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            text
            x-small
            color="error"
            @click="selectedItem = item, isErrorMarkDialogActive = !isErrorMarkDialogActive"
          >
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiFileRemoveOutline }}
            </v-icon>
            <span>Mark Error</span>
          </v-btn>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFileRemoveOutline,
} from '@mdi/js'

import ErrorMarkConfirmation from '@/components/validation/ErrorMarkConfirmation.vue'

import errorMarkService from '@/services/ErrorMarkService'
import useErrorMarkService from '@/store/useErrorMarkService'

import patientService from '@/services/PatientService'
import usePatientService from '@/store/usePatientService'
import moment from 'moment'
import { betweenValidator } from '@core/utils/validation'

export default {
  props: {
    isVitalsListActive: {
      type: Boolean,
      required: true,
    },
    patientData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  model: {
    prop: 'isVitalsListActive',
    event: 'update:is-vitals-list-active',
  },
  components:{
    ErrorMarkConfirmation,
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup(props, { emit }) {
    console.log(props.isVitalsListActive)
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })

    const {
      loading,
      options,

      fetchPatientVitals,
      patientVitalsList,
      patientVitalsColumns,
    } = usePatientService()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isVitalFormActive = ref(false)

    const closeForm = () => {
      emit('update:is-vitals-list-active', false)
    }

    const vitalRec = {
      Temperature: { min: 94, max: 102 },
      Pulse: { min: 60, max: 100 },
      Respirations: { min: 11, max: 35 },
      O2SaturationPercent: { min: 84, max: 100 },
      Systolic: { min: 90, max: 180 },
      Diastolic: { min: 60, max: 120 },
      Weight: { min: 80, max: 650 },
      PrePeakFlow: { min: 200, max: 550 },
      Height: { min: 35, max: 350 },
      PostPeakFlow: { min: 200, max: 550 },
      BloodSugar: { min: 59, max: 356 },
    }

    const isErrorMarkDialogActive = ref(false)

    const ERROR_APP = 'app-error'

    // Register module
    if (!store.hasModule(ERROR_APP)) {
      store.registerModule(ERROR_APP, errorMarkService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_APP)) {
        store.unregisterModule(ERROR_APP)
      }
    })

    const {
      postErrorMark
    } = useErrorMarkService()

    return {
      loading,
      options,

      selectedItem,
      isEdit,

      isVitalFormActive,

      patientVitalsColumns,
      fetchPatientVitals,
      patientVitalsList,

      closeForm,
      vitalRec,
      betweenValidator,

      postErrorMark,
      isErrorMarkDialogActive,

      icons: {
        mdiAccountOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiFileRemoveOutline,
      },
    }
  },
  mounted() {
    console.log('Vitals', this.patientData)
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientVitals(this.patientData.id)
    }
    else if (this.patientId != null && this.patientId !== undefined) {
      this.fetchPatientVitals(this.patientId)
    }

    this.$watch('patientVitalsList', () => {
      this.patientVitalsList.forEach(vitals => {
        vitals.modifiedDate = moment(vitals.modifiedDate).format('YYYY-MM-DD HH:mm')
      })
    })

  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    isBetween (value, index) {
      if(this.betweenValidator(value, this.vitalRec[index]['min'], this.vitalRec[index]['max']) == true){
        return 'Good'
      }
      else{
        return 'Bad'
      }
    },
    fetchData(){
      this.fetchPatientVitals(this.patientData.id)
      this.$watch('patientVitalsList', () => {
        this.patientVitalsList.forEach(vitals => {
          vitals.modifiedDate = moment(vitals.modifiedDate).format('YYYY-MM-DD HH:mm')
        })
      })
    },
    submitErrorMark(errorMarkDTO) {
      errorMarkDTO.patientId = this.patientData.id

      errorMarkDTO.entityJSON.modifiedDate = new Date(errorMarkDTO.entityJSON.modifiedDate)

      console.log('Posting Error Mark', errorMarkDTO)
      this.postErrorMark(errorMarkDTO)
    },
  },
}
</script>

<style>
#temperatureGood {
  color: rgb(22, 187, 22);
}
#temperatureBad {
  color: rgb(240, 41, 41);
}
#respirationsGood {
  color: rgb(22, 187, 22);
}
#respirationsBad {
  color: rgb(240, 41, 41);
}
#o2SaturationPercentGood {
  color: rgb(22, 187, 22);
}
#o2SaturationPercentBad {
  color: rgb(240, 41, 41);
}
#systolicGood {
  color: rgb(22, 187, 22);
}
#systolicBad {
  color: rgb(240, 41, 41);
}
#diastolicGood {
  color: rgb(22, 187, 22);
}
#diastolicBad {
  color: rgb(240, 41, 41);
}
#pulseGood {
  color: rgb(22, 187, 22);
}
#pulseBad {
  color: rgb(240, 41, 41);
}
#weightGood {
  color: rgb(22, 187, 22);
}
#weightBad {
  color: rgb(240, 41, 41);
}
#heightGood {
  color: rgb(22, 187, 22);
}
#heightBad {
  color: rgb(240, 41, 41);
}
#prePeakFlowGood {
  color: rgb(22, 187, 22);
}
#prePeakFlowBad {
  color: rgb(240, 41, 41);
}
#postPeakFlowGood {
  color: rgb(22, 187, 22);
}
#postPeakFlowBad {
  color: rgb(240, 41, 41);
}
#bloodSugarGood {
  color: rgb(22, 187, 22);
}
#bloodSugarBad {
  color: rgb(240, 41, 41);
}



</style>
