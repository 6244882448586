<template>
  <div id="patient-form-list">
    <!-- dialog -->

    <v-dialog
      v-if="selectedForm != null"
      v-model="renderDialog"
      :persistent="isEdit"
      max-width="75%"
    >
      <div>
        <v-card>
          <form-render
            v-if="renderDialog"
            v-model="renderDialog"
            :form-data="selectedForm.formStyle"
            :form-input-data="selectedItem"
            :patient-form-d-t-o="selectedForm"
            :dob="patientData.birthDate"
            :is-edit="isEdit"
            @update-dialog="updateDialog"
            @refetch-data="fetchData"
          ></form-render>
        </v-card>
      </div>
    </v-dialog>

    <div
      v-if="selectedForm != null"
    >
      <delete-confirmation
        v-if="isDeleteDialogActive"
        v-model="isDeleteDialogActive"
        :selected-item="selectedForm"
        @delete-confirmation="submitDelete"
        @refetch-data="fetchData"
      ></delete-confirmation>

      <error-mark-confirmation
        v-if="isErrorMarkDialogActive"
        v-model="isErrorMarkDialogActive"
        :selected-item="selectedForm"
        type="PatientForm"
        @error-mark-confirmation="submitErrorMark"
        @refetch-data="fetchData"
      ></error-mark-confirmation>
    </div>

    <v-card>
      <v-card-title> Patient Forms</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          max-width="20px"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="form-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <task-type-select
          :multiple="false"
          @task-type-select="updateType"
        ></task-type-select>

        <v-spacer></v-spacer>

        <form-select
          @form-select="renderForm"
        ></form-select>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="renderDialog"
            color="primary"
            class="mb-4 me-3"
            @click="selectedItem = {patient: patientId}, updateDialog('render'), isEdit = true"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Form</span>
          </v-btn>
        </div>
      </v-card-text>
      <!-- table -->
      <v-data-table
        v-model="selectedRow"
        :headers="patientFormColumns"
        :items="patientFormList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedForm = item, selectedItem = item.formInput"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateDialog('render'), isEdit = false, renderForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item
                v-if="item.status !== 'Complete'"
                link
              >
                <v-btn
                  text
                  x-small
                  @click="updateDialog('render'), isEdit = true, renderForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isErrorMarkDialogActive = !isErrorMarkDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRemoveOutline }}
                  </v-icon>
                  <span>Mark Error</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiFileRemoveOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'
import FormSelect from '@/components/patient/FormSelect.vue'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import ErrorMarkConfirmation from '@/components/validation/ErrorMarkConfirmation.vue'

import errorMarkService from '@/services/ErrorMarkService'
import useErrorMarkService from '@/store/useErrorMarkService'

export default {
  components: {
    FormSelect,
    FormRender: () => import('@/apps/form/FormRender.vue'),
    TaskTypeSelect,
    DeleteConfirmation,
    ErrorMarkConfirmation,
    },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup() {
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) {
        store.unregisterModule(FORM_APP)
      }
    })

    const {
      fetchPatientForms,
      patientFormList,
      loading,
      searchQuery,
      patientFormColumns,
      selectedForm,
      selectedRow,
      resolveFormStatusVariantAndIcon,
      options,
      statusFilter,
      formData,
      groupFilter,
      deletePatientForm,
      postPatientForm,
    } = useFormService()


    const ERROR_APP = 'app-error'

    // Register module
    if (!store.hasModule(ERROR_APP)) {
      store.registerModule(ERROR_APP, errorMarkService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_APP)) {
        store.unregisterModule(ERROR_APP)
      }
    })

    const {
      postErrorMark
    } = useErrorMarkService()

    const isEdit = ref(false)
    const selectedItem = ref({})
    const isDeleteDialogActive = ref(false)
    const isErrorMarkDialogActive = ref(false)
    const renderDialog = ref(false)

    return {
      patientFormList,
      patientFormColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedForm,
      renderDialog,
      selectedItem,
      selectedRow,
      isEdit,
      isDeleteDialogActive,
      isErrorMarkDialogActive,
      formData,
      fetchPatientForms,
      resolveFormStatusVariantAndIcon,
      groupFilter,

      deletePatientForm,
      postPatientForm,

      postErrorMark,

      icons: {
        mdiAccountOutline,
        mdiFileRemoveOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientForms(this.patientData.id)
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.renderDialog = !this.renderDialog
    },
    submitDelete(formDTO) {
      this.deletePatientForm(formDTO.id)
    },
    submitErrorMark(errorMarkDTO) {
      errorMarkDTO.patientId = this.patientData.id

      errorMarkDTO.entityJSON.modifiedDate = new Date(errorMarkDTO.entityJSON.modifiedDate)

      console.log('Posting Error Mark', errorMarkDTO)
      this.postErrorMark(errorMarkDTO)
    },
    fetchData() {
      this.fetchPatientForms(this.patientData.id)
      this.$watch('patientFormList', () => {
      })
    },
    renderForm(formData) {
      this.selectedForm = formData
      this.selectedItem = formData.formInput
    },
    updateType(item) {
      this.groupFilter = []
      if (item !== null) {
        if (!this.groupFilter.includes(item.display)) {
          this.groupFilter.push(item.display)
        }
      }
      this.fetchPatientForms(this.patientData.id)
      this.$watch('patientFormList', () => {
      })
    },
    updateDialog(dialog) {
      if (dialog === 'render') {
        if (this.renderDialog) {
          this.selectedForm = {}
          this.selectedItem = {}
        }
        if (this.selectedForm == null) {
          this.renderDialog = false
        }
        this.renderDialog = !this.renderDialog
      }
    },
  },
}
</script>
