import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{model:{value:(_vm.isVitalsListActive),callback:function ($$v) {_vm.isVitalsListActive=$$v},expression:"isVitalsListActive"}},[_c(VCard,[_c('patient-vitals-list',{attrs:{"patient-data":_vm.patientData,"is-vitals-list-active":_vm.isVitalsListActive},model:{value:(_vm.isVitalsListActive),callback:function ($$v) {_vm.isVitalsListActive=$$v},expression:"isVitalsListActive"}})],1)],1),_c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Patient Vitals")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.isVitalsListActive = !_vm.isVitalsListActive}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardSubtitle,[_c('span',[_vm._v(" * updated "+_vm._s(_vm.patientVitals.modifiedDate))])]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('p',{staticClass:"text-xs-center"},[_vm._v(" Blood Pressure (mmHg) ")]),_c('h1',[_c('span',{attrs:{"id":'systolic' + _vm.isBetween(_vm.patientVitals.systolic, 'Systolic')}},[_vm._v(" "+_vm._s(_vm.patientVitals.systolic)+" ")]),_vm._v(" / "),_c('span',{attrs:{"id":'diastolic' + _vm.isBetween(_vm.patientVitals.diastolic, 'Diastolic')}},[_vm._v(" "+_vm._s(_vm.patientVitals.diastolic)+" ")])])]),_c(VCol,{attrs:{"cols":"4"}},[_c('p',{staticClass:"text-xs-center"},[_vm._v(" Pulse (BPM) ")]),_c('h1',[_c('div',{attrs:{"id":'pulse' + _vm.isBetween(_vm.patientVitals.pulse, 'Pulse')}},[_vm._v(" "+_vm._s(_vm.patientVitals.pulse)+" ")])])]),_c(VCol,{attrs:{"cols":"4"}},[_c('p',{staticClass:"text-xs-center"},[_vm._v(" Blood Sugar ")]),_c('h1',{attrs:{"id":"bloodSugar"}},[_c('div',{attrs:{"id":'bloodSugar' + _vm.isBetween(_vm.patientVitals.bloodSugar, 'BloodSugar')}},[_vm._v(" "+_vm._s(_vm.patientVitals.bloodSugar)+" ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }