<template>
  <div>
    <v-alert
      v-if="patientData.patientAlerts.length > 0"
      dense
      border="left"
      type="error"
    >
      {{ patientData.displayName }} has active alerts:
      <strong>
        <v-chip
          v-for="(alert, index) in patientData.patientAlerts"
          :key="index"
          outlined
          color="black"
        >
          {{ alert.alert.alertName }}
        </v-chip>
      </strong>
    </v-alert>

    <v-spacer></v-spacer>
    <v-alert
      v-if="patientData.patientAllergies.length > 0"
      dense
      border="left"
      type="warning"
    >
      {{ patientData.displayName }} has active allergies:
      <strong>
        <v-chip
          v-for="(allergy, index) in patientData.patientAllergies"
          :key="index"
          outlined
          color="black"
        >
          {{ allergy.allergyDescriptionCode }}
        </v-chip>
      </strong>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    console.log('Patient Pupop Data', this.patientData )
  }
}

</script>
