<template>
  <div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="isAlertFormActive"
          persistent
          max-width="50%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Alert Details</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="alertForm"
                @submit="validate"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="patientAlertDTO.patientId"
                      label="Patient ID"
                      hint="GUID"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <alert-select
                      v-model="patientAlertDTO.alert"
                      color="primary"
                      label="Alert Description"
                      :alert="patientAlertDTO.alert"
                      :readonly="!isEdit"
                      :multiple="false"
                      :rules="[required]"
                      @alert-select="updateAlert"
                    >
                    </alert-select>
                  </v-col>
                </v-row>


                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuID"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientAlertDTO.startDate"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="Start Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientAlertDTO.startDate"
                        :readonly="!isEdit"
                        :show-current="true"
                        color="primary"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuLO"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientAlertDTO.expectedResolutionDate"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="Expected Resolution Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientAlertDTO.expectedResolutionDate"
                        :show-current="true"
                        :readonly="!isEdit"
                        color="primary"
                        reactive
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                  >
                  <v-textarea
                    outlined
                    v-model="patientAlertDTO.comment"
                    :readonly="!isEdit"
                    label="OnSet Comments"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-switch
                    v-if="isEdit"
                    v-model="patientAlertDTO.isActive"
                    color="primary"
                    :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        {{patientAlertDTO.isActive ? "Active" : "Inactive"}}
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      color="error"
                      type="reset"
                      text
                      @click="closeForm()"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      v-if="isEdit"
                      color="primary"
                      text
                      @click="submitForm()"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import patientService from '@/services/PatientService'
import usePatientList from '@/store/usePatientService'
import moment from 'moment'
import AlertSelect from '@/components/patient/AlertSelect.vue'
import CommonList from '@/store/CommonService'

export default {
  components: {
    AlertSelect,
  },
  model: {
    prop: 'isAlertFormActive',
    event: 'update:is-alert-form-active',
  },
  props: {
    isAlertFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientAlertDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    console.log(props.patientAlertDTO)

    const alertForm = ref(null)
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)

    const validate = patientAlertDTO => {
      if (alertForm.value.validate(patientAlertDTO)) {
        return true
      }

      return false
    }

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update:is-alert-form-active', false)
    }

    const { formatDate } = CommonList()

    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) {
        store.unregisterModule(PATIENT_APP)
      }
    })

    const { postPatientAlert } = usePatientList()

    return {
      alertForm,
      validate,
      closeForm,
      refreshParent,

      postPatientAlert,

      required,
      menuID,
      menuLO,
      menuref,
      save,

      formatDate,

      icons: {
        mdiClose,
      },
    }
  },
  created(){
    if(this.patientAlertDTO.id !== undefined) {
      this.patientAlertDTO.startDate = this.formatDate(this.patientAlertDTO.startDate)
      this.patientAlertDTO.expectedResolutionDate = this.formatDate(this.patientAlertDTO.expectedResolutionDate)
    }
    else{
      this.patientAlertDTO.isActive = true
      this.patientAlertDTO.expectedResolutionDate = this.formatDate(new Date())
      this.patientAlertDTO.startDate = this.formatDate(new Date())
    }
  },
  methods: {
    submitForm() {
      if (this.validate(this.patientAlertDTO)) {
        console.log('posting', this.patientAlertDTO)
        this.postPatientAlert(this.patientAlertDTO)

        this.closeForm()
        this.refreshParent()
      } else {
        console.log('Not Valid')
      }
    },
    updateAlert(alert) {
      this.patientAlertDTO.alertId = alert.id
      console.log('new alert', alert)
      console.log('alert update', this.patientAlertDTO)
    },
  },
}
</script>
